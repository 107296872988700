<template>
    <v-col cols="12" class="pt-0 px-0 car-architecture-page">
        <v-skeleton-loader :loading="true" style="height: 530px;" v-if="metaArchLoading || scanLoading || panelGroupsPosLoading"
          type="image"
        ></v-skeleton-loader>
        <v-card class="d-flex align-center justify-center py-3 position-relative mb-3" v-else>
            <v-btn v-if="model.tab == 'arch' && model.metaModel.dentCount != 0" elevation="2" small :color="color" :style="`color: ${textColor}`" class="position-absolute"
                style="right:2px; top: 2px; z-index: 99;" :to="`/quotes/${model.metaModel.scan_id}`">
                Quote Evaluation <v-icon right class="mx-0 ml-1">mdi-file-chart-outline</v-icon>
            </v-btn>
            <div class="position-relative car-container">
                <img v-if="model.tab == 'arch'" :id="`${model.tab}-architecture-image`" src="@/assets/images/car-sketch.jpg"
                    resize="contain" width="100%" height="500" style="display: block;padding: 20px"/>
                <v-menu offset-y :disabled="model.tab == 'arch' ? true : false" class="w-auto" v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <!-- <img> template used for S3 Annotation Image Issue -->
                        <img width="650" height="380" contain
                           :id="`${model.tab}-architecture-image`"
                           :src="staticImg.thumbnail"
                           class="ma-2 cursor-pointer"
                           crossorigin="anonymous" v-bind="attrs" v-on="on"
                        />
                    </template>
                    <v-list class="scan-edit-options">
                        <v-list-item class="cursor-pointer option" @click="viewImage()">
                            <v-list-item-title>
                                <v-icon color="primary" class="mr-2">mdi-archive-eye-outline </v-icon>
                                View
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="cursor-pointer option" @click="openCrop = staticImg.src">
                            <v-list-item-title>
                                <v-icon color="primary" class="mr-2">mdi-crop</v-icon>
                                Crop
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="cursor-pointer option" @click="showMarkerArea(`${model.tab}-architecture-image`)">
                            <v-list-item-title>
                                <v-icon color="primary" class="mr-2">mdi-image-plus </v-icon>
                                Annotation
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <template v-if="model.tab == 'arch'">
                    <template v-for="(value, key, index) in damageLists">
                        <div :id="`${key.toLowerCase()}`" :key="`${key}-${index}`">
                            <v-tooltip bottom content-class="car-architecture-tooltip" attach :disabled="value.dent_total < 1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip v-bind="attrs" v-on="on" label color="#B54054" v-if="value.dent_total" @click="handleDamageModal(value.group_name)"
                                        class="pa-0 ma-0 pa-2 mx-1 h-auto white--text text-body-1">{{value.dent_total}}</v-chip>
                                </template>
                                <div class="elevation-3">
                                    <v-list shaped>
                                        <div class="d-flex align-center justify-space-between mr-2 ml-3 my-2">
                                            <div dense v-text="'Dent'" class="mr-2 font-weight-bold"></div>
                                            <div dense v-text="value.dent_total" class="font-weight-bold"></div>
                                        </div>
                                        <v-list-item-group dense>
                                            <v-list-item  v-for="(item, i) in value.tooltip" :key="i" dense class="ml-5 mr-4 pr-0 pl-2 border-left">
                                                <v-list-item-icon dense class="mr-3">
                                                    <v-icon :color="item.color" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content dense>
                                                    <div class="d-flex align-center justify-space-between">
                                                        <div dense v-text="item.text" class="mr-5"></div>
                                                        <div dense v-text="item.dent"></div>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </div>
                            </v-tooltip>
                            <v-tooltip bottom content-class="car-architecture-tooltip" attach :disabled="value.scratch_total < 1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip v-bind="attrs" v-on="on" label color="#334A8F" v-if="value.scratch_total" @click="handleDamageModal(value.group_name)"
                                        class="pa-0 ma-0 pa-2 mx-1 h-auto white--text text-body-1">{{value.scratch_total}}</v-chip>
                                </template>
                                <div class="elevation-3">
                                    <v-list shaped>
                                        <div class="d-flex align-center justify-space-between mr-2 ml-3 my-2">
                                            <div dense v-text="'Scratch'" class="mr-2 font-weight-bold"></div>
                                            <div dense v-text="value.scratch_total" class="font-weight-bold"></div>
                                        </div>
                                        <v-list-item-group dense>
                                            <v-list-item  v-for="(item, i) in value.tooltip" :key="i" dense class="ml-5 mr-4 pr-0 pl-2 border-left">
                                                <v-list-item-icon dense class="mr-3">
                                                    <v-icon :color="item.color" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content dense>
                                                    <div class="d-flex align-center justify-space-between">
                                                        <div dense v-text="item.text" class="mr-5"></div>
                                                        <div dense v-text="item.scratch"></div>
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </div>
                            </v-tooltip>
                        </div>
                    </template>
                </template>
            </div>
        </v-card>
        <CropperModal v-if="openCrop" :openCrop="openCrop" @cancel="openCrop = null"
            @cropped="$emit('editedAnnotate', $event); openCrop = null" :name="'Chasis'"/>

        <damage-modal v-if="damageModal.visible" :visible="damageModal.visible" :name="damageModal.selected"
            @close="damageModal = { visible: false, selected: null }" :list="damageLists" :damages="damages" @edited="$emit('editedAnnotate', $event)" />
    </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as markerjs2 from "markerjs2";

import { CAR_ARCH_DATA } from '../service/car-arch-data';

export default {
    props: ['model', 'damages', 'color', 'textColor'],
    emits: ['generateData', 'saved', 'editedAnnotate'],
    components: {
        CropperModal: () => import("./CropperModal"),
        DamageModal: () => import("./damage-details/index")
    },
    data: (vm) => ({
        scanId: vm.$route.params.scanId,
        damageLists: {},
        openCrop: null,
        staticImg: null,
        damageModal: {
            visible: false,
            selected: null
        }
    }),
    computed: {
        ...mapGetters(['scanLoading', 'metaArchLoading', 'archMetaDetails', 'defaultScanClientId',
            'panelGroupsPosition', 'panelGroupsPosLoading'])
    },
    watch: {
        'model.metaModel': function (newValue) {
            if (newValue) this.tabValBind(newValue);
        },
        archMetaDetails(newValue) {
            if (newValue && this.model.tab == 'arch' && !this.panelGroupsPosLoading) this.getCarArchData(newValue);
        },
        panelGroupsPosLoading(value) {
            if(!value && this.archMetaDetails && this.model.tab == 'arch')  this.getCarArchData(this.archMetaDetails);
        },
    },
    created() {
        this.damageLists = this.$helpers.cloneData(CAR_ARCH_DATA(this.panelGroupsPosition));
        if (this.model.metaModel) this.tabValBind(this.model.metaModel);
        if (this.archMetaDetails && this.model.tab == 'arch' && !this.panelGroupsPosLoading) this.getCarArchData(this.archMetaDetails);
    },
    methods: {
        ...mapActions(['searchMetaArchData']),

        handleDamageModal(data) {
            this.damageModal.visible = true;
            this.damageModal.selected = data;
        },
        tabValBind (value) {
            if (this.model.tab != 'arch') this.staticImg = {thumbnail: value.thumbnailImagePathUrl, src: value.srcImagePathUrl || value.thumbnailImagePathUrl};
            else {
                this.staticImg = null;
            }
        },
        async getCarArchData(data) {
            if (data && data.dents) await this.createData(data.dents, 'dent');
            if (data && data.scratchs) await this.createData(data.scratchs, 'scratch');
            this.$emit('generateData', this.damageLists);
        },
        viewImage() {
            const img = [];
            img.push(this.staticImg.src || this.staticImg.thumbnailImagePathUrl);
            this.$viewerApi({
                images: img,
            })
        },
        showMarkerArea(imgId) {
            const target = document.getElementById(imgId);
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.renderImageType = 'image/jpeg';
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener("render", (event) => {
                this.$store.commit('setPageLoading', false);
                this.$emit('editedAnnotate', {value: event.dataUrl, option: 'Annotated'});
            });
            markerArea.show();
        },
        async createData(data, field) {
            await Object.keys(this.damageLists).map(async key => {
                if (data.hasOwnProperty(key)) {
                    this.damageLists[key][`${field}_total`] = Object.keys(data[key]).reduce((sum, subKey) => sum + parseFloat(data[key][subKey].count||0),0);
                    await this.damageLists[key].tooltip.map(async item => {
                        await Object.keys(data[key]).map(subKey => {
                            if (item.text.toLowerCase() === subKey) {
                                item[field] = data[key][subKey].count;
                            }
                        })
                    });
                }
            });
        }
    }
}
</script>

<style>

</style>